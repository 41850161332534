<template>
  <PaymentRow
    :net-amount="netAmountInPLN"
    :tax-value="data.payment.vatPercentage"
    :start-with-gross="startWithGross"
    :focus-input="true"
    @update="updateValue($event)"
  />
</template>

<script>
import PaymentRow from '../Partials/PaymentRow'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
  },
  components: {
    PaymentRow,
  },
  data: () => ({
    netAmountInPLN: null,
    startWithGross: false
  }),
  computed: {
    labels() {
      const { priceLabel } = this.field
      return {
        net: `${priceLabel}NetValue`,
        gross: `${priceLabel}GrossValue`,
      }
    }
  },
  created () {
    this.netAmountInPLN = +this.data.payment?.[this.labels.net] / 100
    this.startWithGross = this.field.gross
  },
  methods: {
    updateValue ({ netValue, grossValue }) {
      this.netAmountInPLN = netValue
      const emitted = {
        [this.labels.net]: +netValue * 100,
        [this.labels.gross]: +grossValue * 100,
      }
      this.$emit('update', emitted)
    }
  }
}
</script>
